// @flow
type Point = Array<number>
type PointsArray = Array<Point>

class Poisson2D {
  k: number
  R: number
  radius: number
  radius2: number
  width: number
  height: number
  cellSize: number
  gridWidth: number
  gridHeight: number
  grid: PointsArray
  queue: PointsArray
  points: PointsArray
  queueSize: number

  // `config` can also be a previous Poisson2D
  constructor(width: number, height: number, config: any = {}) {
    this.width = width
    this.height = height

    this.k = config.k || 30 // Max number of samples before rejection
    this.radius = config.radius || 50
    this.radius2 = this.radius * this.radius // Radius squared
    this.R = 3 * this.radius2

    // Grid is used to optimize search for neighboring points
    this.cellSize = this.radius * Math.SQRT1_2
    this.gridWidth = Math.ceil(width / this.cellSize)
    this.gridHeight = Math.ceil(height / this.cellSize)
    this.grid = new Array(this.gridWidth * this.gridHeight)

    this.queue = []
    this.queueSize = this.queue.length

    // Array to collect output points in
    this.points = []

    // When available, the queue is initialized with existing points
    if (config.points) {
      config.points.forEach(point => {
        this.sample(point) // Will add the point to the appropriate queues etc.
      })
    }
  }

  // Outputs list of points
  generatePoints() {
    // Create initial point
    if (this.queueSize === 0) {
      this.sample([Math.random() * this.width, Math.random() * this.height])
    } // else, the sampler was initialized with existing points

    while (this.queueSize > 0) {
      this.step()
    }

    return this.points
  }

  step() {
    const i = (Math.random() * this.queueSize) | 0
    const s = this.queue[i]
    let j = 0

    const generateCandidate = () => {
      if (++j > this.k) return rejectActive()

      const a = 2 * Math.PI * Math.random()
      const r = Math.sqrt(Math.random() * this.R + this.radius2)
      const x = s[0] + r * Math.cos(a)
      const y = s[1] + r * Math.sin(a)
      const point = [x, y]

      // Reject candidates that are outside the allowed extent.
      if (0 > x || x >= this.width || 0 > y || y >= this.height) {
        return generateCandidate()
      }

      if (this.validPoint(point)) {
        this.sample(point)
      } else {
        generateCandidate()
      }
    }

    const rejectActive = () => {
      this.queue[i] = this.queue[--this.queueSize] // Replace active with last element
      this.queue.length = this.queueSize // Shrink the queue
    }

    generateCandidate()
  }

  // Point is not valid when it is within a radius of any other point
  validPoint(s: Point) {
    const [x, y] = s
    var i = (x / this.cellSize) | 0,
      j = (y / this.cellSize) | 0,
      i0 = Math.max(i - 2, 0),
      j0 = Math.max(j - 2, 0),
      i1 = Math.min(i + 3, this.gridWidth),
      j1 = Math.min(j + 3, this.gridHeight)

    for (j = j0; j < j1; ++j) {
      const o = j * this.gridWidth
      for (i = i0; i < i1; ++i) {
        if ((s = this.grid[o + i])) {
          var dx = s[0] - x,
            dy = s[1] - y
          if (dx * dx + dy * dy < this.radius2) {
            return false
          }
        }
      }
    }

    return true
  }

  sample(s: Point) {
    const [x, y] = s
    this.queue.push(s)
    this.grid[
      this.gridWidth * ((y / this.cellSize) | 0) + ((x / this.cellSize) | 0)
    ] = s
    ++this.queueSize

    this.points.push(s)

    return s
  }
}

export default Poisson2D
