// @flow
import * as React from 'react'
import { css } from 'emotion'
import styled, { keyframes } from 'react-emotion'
import { observer } from 'mobx-react'

import { Content, spacing, colors } from './Theme'
import Logo from './Logo'

type Props = {
  children?: () => React.Node,
  isHome?: boolean,
  title?: string,
  introduction?: string
}

const Container = styled.header`
  display: flex;
  flex-direction: column;
  z-index: 80;
  position: relative;
  width: 100%;
  height: 100vh;
  color: ${colors.white};
`

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Lead = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  z-index: 90;
  margin-bottom: ${spacing.large};
  animation: ${slideIn} 0.6s cubic-bezier(0, 0, 0, 1);
`

const MainTitle = styled.h2`
  color: ${colors.black};
  text-transform: uppercase;
`

const Subtitle = styled.div`
  max-width: 34em;
  font-size: 1.1em;
  margin-top: 0.25em;
  @media screen and (max-width: 599px) {
    font-size: 1.2em;
  }

  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
`

class Header extends React.Component<Props> {
  render() {
    const { isHome, title, introduction, htmlIntroduction } = this.props
    return (
      <Container>
        <Content
          css={`
            height: 100%;
          `}
        >
          <Lead>
            {isHome && (
              <Logo
                fill={colors.white}
                className={css`
                  margin-bottom: 1em;
                `}
              />
            )}
            {title && <MainTitle>{title}</MainTitle>}
            <Subtitle style={{ color: isHome ? 'white' : 'black' }}>
              {htmlIntroduction ? (
                <div dangerouslySetInnerHTML={{ __html: htmlIntroduction }} />
              ) : null}
              {introduction}
            </Subtitle>
          </Lead>
        </Content>
      </Container>
    )
  }
}

export default observer(Header)
