// @flow
import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'react-emotion'
import { css } from 'emotion'
import { action, extendObservable } from 'mobx'
import { observer } from 'mobx-react'

import Logo from './Logo'
import { Content, colors } from './Theme'
import Routes from './Routes'

const Navbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 990;
  box-shadow: ${props => (props.showShadow ? '0 0 1px rgba(0,0,0,0.5)' : 0)};
  transition: background 0.5s ease;
  background: ${props => (props.showShadow ? 'white' : 'transparent')};
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.45rem 0;
  @media screen and (max-width: 799px) {
    flex-direction: column;
  }
`

const TopNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MainMenu = styled.ul`
  display: ${props =>
    props.isHidden ? (props.isHome ? 'flex' : 'none') : 'flex'};
  flex: 0 1 auto;
  margin: 0;
  justify-content: flex-end;
  list-style-type: none;
  font-size: 1.2em;
  width: 100%;
  color: ${props => (props.isHome ? colors.white : colors.black)};
  @media screen and (min-width: 800px) {
    display: flex !important;
  }
  @media screen and (max-width: 799px) {
    flex-direction: column;
    margin-top: ${props => (props.isHidden ? 0 : props.isHome ? 0 : '1em')};
  }
`

const MenuLinkStyle = css`
  font-family: 'Jungka', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin-left: 1em;
  border-bottom: 2px solid transparent;
  flex: none;
  display: inline-block;
  padding-bottom: 0.1em;
  @media screen and (max-width: 799px) {
    margin-left: 0;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
  }
  &:hover {
    border-color: inherit;
  }
`

const MenuLinkActive = css`
  ${MenuLinkStyle};
  border-color: inherit;
`

const MobileMenuButton = styled.button`
  display: none;
  border: none;
  background: none;
  cursor: pointer;
  @media screen and (max-width: 799px) {
    display: block;
  }
`

class NavbarComponent extends React.Component<any> {
  constructor() {
    super()
    extendObservable(this, { hidden: true })
  }

  toggleHidden = action(force => {
    this.hidden = force !== undefined ? force : !this.hidden
  })

  componentDidUpdate(prevProps: any) {
    if (this.props.isHome !== prevProps.isHome) {
      this.toggleHidden(true)
    }
    if (
      this.props.hideNavigation !== prevProps.hideNavigation &&
      !this.hidden
    ) {
      this.toggleHidden(true)
    }
  }

  render() {
    const { isHome, hideNavigation } = this.props
    return (
      <Navbar showShadow={hideNavigation}>
        <Content>
          <Wrapper>
            {(!isHome || hideNavigation) && (
              <TopNavigation>
                <Link
                  css={`
                    flex: 0 0 auto;
                    display: flex;
                    align-items: center;
                  `}
                  to="/"
                >
                  <Logo fill={colors.black} maxHeight="1.4rem" />
                </Link>

                <MobileMenuButton onClick={() => this.toggleHidden()}>
                  {this.hidden ? 'Menu' : 'Close'}
                </MobileMenuButton>
              </TopNavigation>
            )}
            <MainMenu isHidden={this.hidden} isHome={isHome && !hideNavigation}>
              {Routes.map((page, i) => (
                <li key={`menu-${i}`}>
                  {page.external ? (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={page.url}
                      className={MenuLinkStyle}
                    >
                      {page.title}
                    </a>
                  ) : (
                    <Link
                      className={MenuLinkStyle}
                      activeClassName={MenuLinkActive}
                      to={page.url}
                    >
                      {page.title}
                    </Link>
                  )}
                </li>
              ))}
            </MainMenu>
          </Wrapper>
        </Content>
      </Navbar>
    )
  }
}

export default observer(NavbarComponent)
