// @flow
import * as React from 'react'
import {
  Object3D,
  PerspectiveCamera,
  Scene,
  Group,
  CylinderBufferGeometry,
  BoxBufferGeometry,
  LineDashedMaterial,
  LineBasicMaterial,
  LineSegments,
  EdgesGeometry,
  Vector2,
  Vector3
} from 'three'
import { Math as ThreeMath } from 'three'
import TWEEN from '@tweenjs/tween.js'
import styled, { keyframes } from 'react-emotion'
import sampleSize from 'lodash/sampleSize'
import range from 'lodash/range'

import { colors } from './Theme'
import { SVGRenderer } from '../lib/three/SVGRender'
import { halfPI } from '../lib/constants'

import Poisson2D from '../lib/poisson'

type Props = {
  isHome: boolean,
  location: {
    pathname: string
  }
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ThreeContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  display: block;
  z-index: -1;
  pointer-events: none;
  background: ${props => (props.isHome ? colors.dark : 'transparent')};
  svg {
    opacity: 0;
    animation: 0.5s ease-out 0s forwards ${fadeIn};
  }
`

const createExtrudedShape = ({ form, width, height, depth, dashed, color }) => {
  let geometry
  switch (form) {
    case '0':
      geometry = new CylinderBufferGeometry(width / 2, width / 2, depth, 20)
      break
    case '3':
      geometry = new CylinderBufferGeometry(width * 0.6, width * 0.6, depth, 3)
      break
    case '4':
      geometry = new BoxBufferGeometry(width, depth, height)
      break
    case '5':
      geometry = new CylinderBufferGeometry(
        width * 0.55,
        width * 0.55,
        depth,
        5
      )
      break
    case '6':
      geometry = new CylinderBufferGeometry(
        width * 0.55,
        width * 0.55,
        depth,
        6
      )
      break
    case '7':
      geometry = new CylinderBufferGeometry(width / 2, width / 2, depth, 7)
      break
    case '8':
      geometry = new CylinderBufferGeometry(
        width * 0.55,
        width * 0.55,
        depth,
        8
      )
      break
    default:
      geometry = new BoxBufferGeometry(width, height, depth)
      break
  }

  let lineGeometry = new EdgesGeometry(geometry)
  let material = dashed
    ? new LineDashedMaterial({
        color,
        dashSize: 20,
        gapSize: 12,
        linewidth: 2,
        linecap: 'round',
        linejoin: 'round'
      })
    : new LineBasicMaterial({
        color,
        linewidth: 2,
        linecap: 'round',
        linejoin: 'round'
      })
  let mesh = new LineSegments(lineGeometry, material)
  mesh.position.set(-width / 2, -height / 2, 0)

  const parentMesh = new Object3D()
  parentMesh.rotateX(Math.random() * Math.PI - halfPI)
  parentMesh.rotateY(Math.random() * Math.PI - halfPI)
  parentMesh.rotateZ(Math.random() * Math.PI - halfPI)
  parentMesh.add(mesh)

  parentMesh.userData.speed = Math.random()

  return parentMesh
}

class Three extends React.Component<Props> {
  canvas: HTMLDivElement
  renderer: typeof SVGRenderer
  camera: typeof PerspectiveCamera
  scene: typeof Scene
  group: typeof Group
  originalWidth: number
  originalHeight: number
  width: number
  height: number
  shouldAnimate: ?boolean
  timer: any
  poisson: ?Poisson2D

  componentDidMount() {
    if (this.canvas) {
      const canvasWidth = this.canvas.getBoundingClientRect().width
      const canvasHeight = this.canvas.getBoundingClientRect().height
      this.originalWidth = canvasWidth
      this.originalHeight = canvasHeight

      this.renderer = new SVGRenderer()
      this.renderer.setClearColor('white', 0)
      this.canvas.appendChild(this.renderer.domElement)
      this.camera = new PerspectiveCamera(
        65,
        canvasWidth / canvasHeight,
        100,
        1500
      )

      const vFOV = ThreeMath.degToRad(this.camera.fov)
      const dist = canvasHeight / 2 / Math.tan(vFOV / 2)
      this.camera.position.set(0, 0, -dist)
      this.camera.lookAt(new Vector3(0, 0, 0))

      this.scene = new Scene()
      this.group = new Group()
      this.scene.add(this.group)

      window.addEventListener('resize', this.resize, false)

      this.resize()
      this.timer = setTimeout(this.startRandomTween, 5000)
      window.requestAnimationFrame(this.renderThree)
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.location.pathname !== this.props.location.pathname
  }

  checkVisibility(position: { x: number, y: number }) {
    const height = this.height / 2
    const width = this.width
    const yThreshold = -height + height / 1.5
    const yThreshold2 = -height + height / 3.5
    const xThresholdLeft = width * 0.1
    const xThresholdRight = -(width / 5)
    return width < 1100
      ? width < 700 && height < 700
        ? position.y > 0
        : position.y > yThreshold
      : position.y > yThreshold ||
          (position.y > yThreshold2 &&
            position.x > width / 2 - xThresholdLeft) ||
          (position.y > yThreshold2 && position.x < xThresholdRight)
  }

  createBlocks(points: Array<{ x: number, y: number }>) {
    const color = this.props.isHome ? 0xff005e : 0x0000ff
    for (let i = 0; i < points.length; i++) {
      let form = Math.floor(Math.random() * 6 + 2)
      if (form === 2) form = 0
      const position = points[i]

      let shape = createExtrudedShape({
        form: form.toString(),
        width: 10 * Math.random() * 7 + 15,
        height: 10 * Math.random() * 7 + 15,
        depth: 10 * Math.random() * 3.5 + 5,
        dashed: false,
        color
      })
      shape.visible = this.checkVisibility(position)
      shape.position.set(position.x, position.y, 0)
      this.group.add(shape)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const changedFromHome = this.props.isHome || prevProps.isHome
      this.startTween(changedFromHome, this.props.isHome)
    }
  }

  startRandomTween = () => {
    if (!this.canvas) return

    this.start()
    if (this.timer) clearTimeout(this.timer)

    const minimum = Math.min(this.group.children.length, 3)
    const childCount = Math.max(
      minimum,
      Math.ceil(this.group.children.length * 0.15)
    )
    let selectedChildren = sampleSize(this.group.children, childCount)
    let randomStartTimes = range(childCount).map(() => {
      return Math.floor(Math.random() * 400)
    })
    let randomDurationTimes = range(childCount).map(() => {
      return Math.floor(Math.random() * 600 + 400)
    })
    let completeCount = 0
    selectedChildren.forEach((shape, index) => {
      let rotation = { theta: 0 }
      let randomAxis = sampleSize(['x', 'y', 'z'], 1)
      let randomOffset = Math.random() * 300 + index * 150
      new TWEEN.Tween(rotation)
        .to({ theta: Math.random() * 0.25 + 0.1 }, randomDurationTimes[index])
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(val => {
          shape.children[0].rotation[randomAxis] =
            shape.children[0].rotation[randomAxis] +
            val.theta * shape.userData.speed
        })
        .delay(randomStartTimes[index] + randomOffset)
        .onComplete(() => {
          completeCount++
          if (completeCount === childCount) {
            this.stop()
            this.timer = setTimeout(this.startRandomTween, 8000)
          }
        })
        .start()
    })
  }

  startTween = (changedFromHome: boolean = false, isHome: boolean) => {
    if (!this.canvas) return

    this.start()
    if (this.timer) clearTimeout(this.timer)

    if (changedFromHome) {
      this.group.children.forEach(shape => {
        shape.children[0].material.color.setHex(isHome ? 0xff005e : 0x0000ff)
      })
    }

    let rotation = { theta: 0 }
    new TWEEN.Tween(rotation)
      .to({ theta: 0.25 }, 600)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(val => {
        this.group.children.forEach(shape => {
          if (!changedFromHome) {
            shape.visible = this.checkVisibility(shape.position)
          }
          shape.children[0].rotation.y =
            shape.children[0].rotation.y + val.theta * shape.userData.speed
        })
      })
      .onComplete(() => {
        this.stop()
        this.timer = setTimeout(this.startRandomTween, 8000)
      })
      .start()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize, false)
  }

  start() {
    this.shouldAnimate = true
    window.requestAnimationFrame(this.animate)
  }

  stop() {
    this.shouldAnimate = false
    TWEEN.removeAll()
  }

  animate = (time: number) => {
    this.renderThree(time)
    if (this.shouldAnimate) window.requestAnimationFrame(this.animate)
  }

  renderThree = (time: number) => {
    TWEEN.update(time)
    this.renderer.render(this.scene, this.camera)
  }

  resize = () => {
    this.height = this.canvas.getBoundingClientRect().height
    this.width = this.canvas.getBoundingClientRect().width
    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.width, this.height)
    window.requestAnimationFrame(this.animate)

    this.group.children.forEach(shape => {
      shape.visible = this.checkVisibility(shape.position)
    })

    this.expandPoisson()
  }

  expandPoisson() {
    const existingPointCount = this.poisson ? this.poisson.points.length : 0

    if (this.poisson) {
      if (
        this.width <= this.poisson.width &&
        this.height <= this.poisson.height
      ) {
        return
      }

      this.poisson.points = this.poisson.points.map(point => {
        // Translate points in new canvas space
        return [
          point[0] - this.poisson.width / 2 + this.width / 2,
          point[1] - this.poisson.height / 2 + this.height / 2
        ]
      })
    }
    this.poisson = new Poisson2D(
      this.width,
      this.height,
      this.poisson || { radius: this.width < 640 ? 150 : 180 } // Either use existing poisson as config, or create a new one
    )
    const points = this.poisson.generatePoints().map(point => {
      const [x, y] = point
      return new Vector2(x - this.width / 2, y - this.height / 2)
    })

    // Create blocks, but only for new points (which have been appended to the existing array)
    this.createBlocks(points.slice(existingPointCount))
  }

  render(): React.Node {
    return (
      <ThreeContainer
        isHome={this.props.isHome}
        innerRef={c => (this.canvas = c)}
      />
    )
  }
}

export default Three
