// @flow
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'react-emotion'
import { keyframes } from 'react-emotion'
import { StaticQuery, graphql } from 'gatsby'

import './index.css'
import './fonts.css'

import Navigation from '../Navigation'
import Three from '../Three'
import Footer from '../Footer'

type Props = {
  children: () => React.Node,
  location: {
    pathname: string
  },
  data: {
    site: {
      siteMetadata: {
        title: string,
        description: string,
        keywords: string
      }
    }
  }
}

type State = {
  hideHeader: boolean,
  hideNavigation: boolean
}

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  transition: transform 0.3s ease;
  z-index: 224;
  animation: ${FadeIn} 0.4s ease;
`

class Layout extends React.Component<Props, State> {
  offset: number
  navigationOffset: number

  constructor() {
    super()
    this.state = {
      hideHeader: false,
      hideNavigation: false
    }
  }

  componentDidMount() {
    this.updateOffset()
    window.addEventListener('scroll', this.updateOpacity)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateOpacity)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname)
      this.updateOffset()
  }

  updateOffset = () => {
    this.offset =
      this.props.location.pathname === '/'
        ? window.innerHeight / 1.5
        : window.innerHeight / 2.2
    this.navigationOffset =
      window.innerWidth < 800 ? window.innerHeight / 2.8 : this.offset
    this.updateOpacity()
  }

  updateOpacity = () => {
    if (window.pageYOffset > this.offset) {
      if (!this.state.hideHeader) {
        this.setState({ hideHeader: true })
      }
    } else {
      if (this.state.hideHeader) {
        this.setState({ hideHeader: false })
      }
    }
    if (window.pageYOffset > this.navigationOffset) {
      if (!this.state.hideNavigation) {
        this.setState({ hideNavigation: true })
      }
    } else {
      if (this.state.hideNavigation) {
        this.setState({ hideNavigation: false })
      }
    }
  }

  render() {
    const { location } = this.props
    const isHome = location.pathname === '/'

    return (
      <React.Fragment>
        <StaticQuery
          query={graphql`
            query Layout {
              site {
                siteMetadata {
                  title
                  description
                  keywords
                }
              }
            }
          `}
          render={data => (
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content: data.site.siteMetadata.description
                },
                { name: 'keywords', content: data.site.siteMetadata.keywords }
              ]}
            />
          )}
        />
        <Navigation
          location={location}
          isHome={isHome}
          hideNavigation={this.state.hideNavigation}
        />
        <Three location={location} isHome={isHome} />
        <Wrapper>{this.props.children}</Wrapper>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Layout
