import React from 'react'
import styled from 'react-emotion'
import { css } from 'emotion'
import Media from 'react-media'
import Helmet from 'react-helmet'

const DEFAULT_DESCRIPTION =
  'Next Empire is a digital product and experience studio based in amsterdam.'

export const maxWidth = '960px'
export const padding = 1

export const spacing = {
  small: `${padding * 1}em`,
  medium: `${padding * 2}em`,
  large: `${padding * 4}em`,
  xlarge: `${padding * 6}em`,
  xxlarge: `${padding * 9}em`
}

export const colors = {
  white: 'rgb(255,255,255)',
  black: 'hsla(0, 0%, 0%, 0.8)',
  blue: 'rgb(0,0,255)',
  pink: 'rgb(255,0,94)',
  dark: 'rgb(17,10,48)',
  yellow: 'rgb(245,236,78)',
  gray: 'rgb(195,195,200)'
}

export const singleLine = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${maxWidth};
  padding: 0 ${spacing.small};
  display: flex;
  flex-direction: column;
`

export const Top = styled.header`
  margin-bottom: ${spacing.large};
`

export const Section = styled.section`
  margin-bottom: ${spacing.large};
  a {
    color: blue;
    &:hover {
      color: inherit;
    }
  }
`

export const MobileScreen = ({ children }) => (
  <Media query={{ maxWidth: 599 }}>{matches => children(matches)}</Media>
)

export const TabletScreen = ({ children }) => (
  <Media query={{ maxWidth: 959 }}>{matches => children(matches)}</Media>
)

export const TabletOnly = ({ children }) => (
  <Media query={{ maxWidth: 959, minWidth: 600 }}>
    {matches => children(matches)}
  </Media>
)

const favicons = [
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicons/favicon-16x16.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '96x96',
    href: '/favicons/favicon-96x96.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicons/favicon-32x32.png'
  }
]

export const Meta = ({
  title,
  image, // REPLACE WITH DEFAULT: require('../pages/about/team/ingo.jpg'),
  description,
  url
}) => {
  const newTitle = title ? `Next Empire – ${title}` : 'Next Empire'
  return (
    <Helmet link={favicons}>
      <title>{newTitle}</title>
      <meta name="description" content={description || DEFAULT_DESCRIPTION} />
      <meta property="og:title" content={newTitle} />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={description || DEFAULT_DESCRIPTION}
      />
      <meta property="og:site_name" content="Next Empire" />
      <meta property="og:url" content={url || 'https://nextempire.net'} />
      {image && <meta property="og:image" content={image} />}
      {/* <meta property="og:image:width" content={imageWidth} />
    <meta property="og:image:height" content={imageHeight} /> */}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export const uppercase = css`
  text-transform: uppercase;
`

export const Lead = styled.div`
  font-size: 1.1em;
`

export const widths = {
  maxWidth,
  mobile: '599px',
  tablet: '959px'
}
