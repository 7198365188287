// @flow
import * as React from 'react'
import styled from 'react-emotion'
import { css } from 'emotion'
import { Link } from 'gatsby'

import { Content, widths, spacing, TabletOnly, colors } from './Theme'
import Routes from './Routes'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 6em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const Row = styled.div`
  display: flex;
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: ${spacing.large} 0;
  font-size: 0.75em;
  width: 100%;
  @media screen and (max-width: ${widths.tablet}) {
    flex-direction: column;
    width: 100%;
  }
  @media screen and (max-width: ${widths.mobile}) {
    font-size: 0.85em;
  }
`

const Column = styled.div`
  width: 25%;
  flex: 0 0 auto;
  @media screen and (max-width: ${widths.tablet}) {
    margin-bottom: 1em;
    width: 100%;
  }
  @media screen and (max-width: ${widths.mobile}) {
    width: 100%;
  }
`

const MenuLinkStyle = css`
  &:hover {
    border-bottom: 1px solid ${colors.black};
  }
`

const MenuLinkActive = css`
  ${MenuLinkStyle};
  border-bottom: 1px solid ${colors.black};
`

const ExternalLink = styled.a`
  ${MenuLinkStyle};
  opacity: ${props => (props.secondary ? 0.6 : 1)};
`

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
`

const MenuColumn = () => (
  <Column>
    <FooterMenu>
      {Routes.map((page, i) => (
        <li
          css={`
            margin: 0;
          `}
          key={`menu-${i}`}
        >
          {page.external ? (
            <ExternalLink href={page.url}>{page.title}</ExternalLink>
          ) : (
            <Link
              className={MenuLinkStyle}
              activeClassName={MenuLinkActive}
              to={page.url}
            >
              {page.title}
            </Link>
          )}
        </li>
      ))}
    </FooterMenu>
  </Column>
)

export default () => (
  <Wrapper>
    <Content>
      <TabletOnly>
        {isTablet => (
          <Row>
            <Footer>
              {!isTablet && <MenuColumn />}
              <Column>
                <ExternalLink href="mailto:hello@nextempire.net">
                  hello@nextempire.net
                </ExternalLink>
                <br />
                <ExternalLink href="tel:0031207165969">
                  +31 207 165 969
                </ExternalLink>
              </Column>
              <Column>
                Pazzanistraat 3
                <br />
                1014DB, Amsterdam
                <br />
                <ExternalLink
                  rel="noopener noreferrer"
                  target="_blank"
                  secondary
                  href="https://goo.gl/maps/XnZFi3LoT6U2"
                >
                  Route›
                </ExternalLink>
              </Column>
              <Column>
                <ExternalLink href="https://instagram.com/next.empire">
                  Instagram
                </ExternalLink>
                <br />
                <ExternalLink href="https://facebook.com/NXTMPR/">
                  Facebook
                </ExternalLink>
              </Column>
            </Footer>
            {isTablet && (
              <Footer>
                <MenuColumn />
              </Footer>
            )}
          </Row>
        )}
      </TabletOnly>
    </Content>
  </Wrapper>
)
